.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5%;
   }
   
   .text {
     font-size: 170%;
     padding-left: 12%;
   }

.new-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5%;
   }

   .new-text {
    font-size: 170%;
  }